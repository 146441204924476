@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");

*,
:after,
:before {
  box-sizing: border-box;
}
:root {
  --color: #3e4c59;
  --bgColor: #eff1f9;
}

body {
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  color: var(--color);
  font-size: 16px;
  background-color: var(--bgColor);
}

button,
input,
optgroup,
select,
textarea {
  font-family: Roboto, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Roboto, sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  min-height: 100%;
}

.VideoJsContainer {
  > div {
    height: 100%;
  }
  .rh5v-DefaultPlayer_component {
    background-color: transparent;
  }
}

.none-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}

.tippy-popper {
  word-break: break-all;
}
.tippy-tooltip.transparent-theme [x-circle] {
  background-color: transparent !important;
}
