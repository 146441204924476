@font-face {
  font-family: "lumio-icon";
  src: url("./fonts/lumio-icon-v2.13/fonts/lumio-icon.eot?p2uhxn");
  src: url("./fonts/lumio-icon-v2.13/fonts/lumio-icon.eot?p2uhxn#iefix")
      format("embedded-opentype"),
    url("./fonts/lumio-icon-v2.13/fonts/lumio-icon.ttf?p2uhxn")
      format("truetype"),
    url("./fonts/lumio-icon-v2.13/fonts/lumio-icon.woff?p2uhxn") format("woff"),
    url("./fonts/lumio-icon-v2.13/fonts/lumio-icon.svg?p2uhxn#lumio-icon")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="lumio-icon-"],
[class*=" lumio-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lumio-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lumio-icon-volume_off:before {
  content: "\e900";
}
.lumio-icon-search:before {
  content: "\e001";
}
.lumio-icon-close:before {
  content: "\e002";
}
.lumio-icon-caret_up:before {
  content: "\e003";
}
.lumio-icon-caret_down:before {
  content: "\e004";
}
.lumio-icon-move:before {
  content: "\e005";
}
.lumio-icon-delete2:before {
  content: "\e006";
}
.lumio-icon-edit:before {
  content: "\e007";
}
.lumio-icon-preview:before {
  content: "\e008";
}
.lumio-icon-folder:before {
  content: "\e009";
}
.lumio-icon-clip:before {
  content: "\e010";
}
.lumio-icon-duplicate:before {
  content: "\e011";
}
.lumio-icon-sort:before {
  content: "\e012";
}
.lumio-icon-tags:before {
  content: "\e013";
}
.lumio-icon-upload:before {
  content: "\e014";
}
.lumio-icon-usage:before {
  content: "\e015";
}
.lumio-icon-support:before {
  content: "\e901";
}
.lumio-icon-cam:before {
  content: "\e902";
}
.lumio-icon-link:before {
  content: "\e903";
}
.lumio-icon-circle_forward:before {
  content: "\e904";
}
.lumio-icon-drag:before {
  content: "\e905";
}
.lumio-icon-collage:before {
  content: "\e906";
}
.lumio-icon-notification:before {
  content: "\e907";
}
.lumio-icon-dot:before {
  content: "\e908";
}
.lumio-icon-alert:before {
  content: "\e909";
}
.lumio-icon-network:before {
  content: "\e90a";
}
.lumio-icon-rtc:before {
  content: "\e90b";
}
.lumio-icon-storage:before {
  content: "\e90c";
}
.lumio-icon-approval:before {
  content: "\e90d";
}
.lumio-icon-warning:before {
  content: "\e90e";
}
.lumio-icon-error:before {
  content: "\e90f";
}
.lumio-icon-circle_clock:before {
  content: "\e910";
}
.lumio-icon-drop:before {
  content: "\e911";
}
.lumio-icon-circle_crossed:before {
  content: "\e912";
}
.lumio-icon-group:before {
  content: "\e913";
}
.lumio-icon-schedule:before {
  content: "\e914";
}
.lumio-icon-timeline:before {
  content: "\e915";
}
.lumio-icon-plus:before {
  content: "\e916";
}
.lumio-icon-vertical_dots:before {
  content: "\e917";
}
.lumio-icon-horizontal_dots:before {
  content: "\e918";
}
.lumio-icon-abc1:before {
  content: "\e919";
}
.lumio-icon-account:before {
  content: "\e91a";
}
.lumio-icon-activities:before {
  content: "\e91b";
}
.lumio-icon-alignment_center:before {
  content: "\e91c";
}
.lumio-icon-alignment_left:before {
  content: "\e91d";
}
.lumio-icon-alignment_right:before {
  content: "\e91e";
}
.lumio-icon-audio_clip:before {
  content: "\e91f";
}
.lumio-icon-audio:before {
  content: "\e920";
}
.lumio-icon-canvas:before {
  content: "\e921";
}
.lumio-icon-circle_backward:before {
  content: "\e922";
}
.lumio-icon-circle_next:before {
  content: "\e923";
}
.lumio-icon-checked:before {
  content: "\e924";
}
.lumio-icon-chevron_left:before {
  content: "\e925";
}
.lumio-icon-chevron_right:before {
  content: "\e926";
}
.lumio-icon-circle_checked:before {
  content: "\e927";
}
.lumio-icon-client_access:before {
  content: "\e928";
}
.lumio-icon-clients:before {
  content: "\e929";
}
.lumio-icon-circle_previous:before {
  content: "\e92a";
}
.lumio-icon-clock:before {
  content: "\e92b";
}
.lumio-icon-circle_stop:before {
  content: "\e92c";
}
.lumio-icon-collapse:before {
  content: "\e92d";
}
.lumio-icon-contents:before {
  content: "\e92e";
}
.lumio-icon-dashboard:before {
  content: "\e92f";
}
.lumio-icon-delete:before {
  content: "\e930";
}
.lumio-icon-devices:before {
  content: "\e931";
}
.lumio-icon-download:before {
  content: "\e932";
}
.lumio-icon-dragable:before {
  content: "\e933";
}
.lumio-icon-circle_pause:before {
  content: "\e934";
}
.lumio-icon-circle_play:before {
  content: "\e935";
}
.lumio-icon-next:before {
  content: "\e936";
}
.lumio-icon-expand:before {
  content: "\e937";
}
.lumio-icon-facebook:before {
  content: "\e938";
}
.lumio-icon-feed_clip:before {
  content: "\e939";
}
.lumio-icon-previous:before {
  content: "\e93a";
}
.lumio-icon-grid:before {
  content: "\e93b";
}
.lumio-icon-hardware:before {
  content: "\e93c";
}
.lumio-icon-heartbeat:before {
  content: "\e93d";
}
.lumio-icon-help:before {
  content: "\e93e";
}
.lumio-icon-image:before {
  content: "\e93f";
}
.lumio-icon-info_outline:before {
  content: "\e940";
}
.lumio-icon-instagram:before {
  content: "\e941";
}
.lumio-icon-inventory:before {
  content: "\e942";
}
.lumio-icon-list:before {
  content: "\e943";
}
.lumio-icon-loop_clip:before {
  content: "\e944";
}
.lumio-icon-media_clip:before {
  content: "\e945";
}
.lumio-icon-media:before {
  content: "\e946";
}
.lumio-icon-megaphone:before {
  content: "\e947";
}
.lumio-icon-last:before {
  content: "\e948";
}
.lumio-icon-new_tab:before {
  content: "\e949";
}
.lumio-icon-organization:before {
  content: "\e94a";
}
.lumio-icon-orientation_landscape_flip:before {
  content: "\e94b";
}
.lumio-icon-orientation_landscape:before {
  content: "\e94c";
}
.lumio-icon-orientation_portrait_flip:before {
  content: "\e94d";
}
.lumio-icon-orientation_portrait:before {
  content: "\e94e";
}
.lumio-icon-first:before {
  content: "\e94f";
}
.lumio-icon-report:before {
  content: "\e950";
}
.lumio-icon-report2:before {
  content: "\e951";
}
.lumio-icon-roots:before {
  content: "\e952";
}
.lumio-icon-rows:before {
  content: "\e953";
}
.lumio-icon-rss:before {
  content: "\e954";
}
.lumio-icon-forward:before {
  content: "\e955";
}
.lumio-icon-settings:before {
  content: "\e956";
}
.lumio-icon-share:before {
  content: "\e957";
}
.lumio-icon-backward:before {
  content: "\e958";
}
.lumio-icon-stream_clip:before {
  content: "\e959";
}
.lumio-icon-stop:before {
  content: "\e95a";
}
.lumio-icon-team:before {
  content: "\e95b";
}
.lumio-icon-template_clip:before {
  content: "\e95c";
}
.lumio-icon-twitter:before {
  content: "\e95d";
}
.lumio-icon-pause:before {
  content: "\e95e";
}
.lumio-icon-upload2:before {
  content: "\e95f";
}
.lumio-icon-play:before {
  content: "\e960";
}
.lumio-icon-video:before {
  content: "\e961";
}
.lumio-icon-view:before {
  content: "\e962";
}
.lumio-icon-vms_clip:before {
  content: "\e963";
}
.lumio-icon-vms2_clip:before {
  content: "\e964";
}
.lumio-icon-webview_clip:before {
  content: "\e965";
}
.lumio-icon-identify:before {
  content: "\e966";
}
.lumio-icon-shell_off:before {
  content: "\e967";
}
.lumio-icon-shell_on:before {
  content: "\e968";
}
.lumio-icon-snap:before {
  content: "\e969";
}
.lumio-icon-call_icmp:before {
  content: "\e96a";
}
.lumio-icon-call_web:before {
  content: "\e96b";
}
.lumio-icon-call_intent:before {
  content: "\e96c";
}
.lumio-icon-clear_wcache:before {
  content: "\e96d";
}
.lumio-icon-set_vol:before {
  content: "\e96e";
}
.lumio-icon-set_bright:before {
  content: "\e96f";
}
.lumio-icon-change_display_res:before {
  content: "\e970";
}
.lumio-icon-adb_off:before {
  content: "\e971";
}
.lumio-icon-adb_on:before {
  content: "\e972";
}
.lumio-icon-ping:before {
  content: "\e973";
}
.lumio-icon-stat:before {
  content: "\e974";
}
.lumio-icon-clear_cache:before {
  content: "\e975";
}
.lumio-icon-restart:before {
  content: "\e976";
}
.lumio-icon-reboot:before {
  content: "\e977";
}
.lumio-icon-automation:before {
  content: "\e978";
}
.lumio-icon-traffic:before {
  content: "\e979";
}
.lumio-icon-audience:before {
  content: "\e97a";
}
.lumio-icon-arrow_left_right:before {
  content: "\e97b";
}
.lumio-icon-ams:before {
  content: "\e97c";
}
.lumio-icon-lock:before {
  content: "\e97d";
}
.lumio-icon-unlock:before {
  content: "\e97e";
}
.lumio-icon-lock_checked:before {
  content: "\e97f";
}
.lumio-icon-notes:before {
  content: "\e980";
}
.lumio-icon-refresh_content:before {
  content: "\e981";
}
.lumio-icon-refresh_web:before {
  content: "\e982";
}
.lumio-icon-valign_bottom:before {
  content: "\e983";
}
.lumio-icon-valign_center:before {
  content: "\e984";
}
.lumio-icon-valign_top:before {
  content: "\e985";
}
.lumio-icon-pooh_clip:before {
  content: "\e986";
}
.lumio-icon-power_settings:before {
  content: "\e987";
}
.lumio-icon-4k_plus:before {
  content: "\e988";
}
.lumio-icon-4k:before {
  content: "\e989";
}
.lumio-icon-hd:before {
  content: "\e98a";
}
.lumio-icon-high_quality:before {
  content: "\e98b";
}
.lumio-icon-high_res:before {
  content: "\e98c";
}
.lumio-icon-repeat:before {
  content: "\ea2e";
}
