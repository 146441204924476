$border-color: #dddddd;
$error-color: #f44336;
$success-color: #8bc34a;
$warning-color: #ffc107;
$lumio-color: #2892fe;

.form-control {
  .Select {
    margin-bottom: 5px;
    height: auto;

    &.is-open {
      .Select-control {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .Select-placeholder {
      top: 4px;
    }

    .Select-control {
      border: 1px solid $border-color;
      border-radius: 3px;
      height: auto;

      .Select-multi-value-wrapper {
        height: auto;

        .Select-placeholder {
          height: auto;
          padding: 10px 10px;
          line-height: normal;
          font-size: 1em;
        }

        .Select-input {
          height: auto;
          line-height: normal;
          font-size: 1em;

          > input {
            padding: 14px 0;
            line-height: normal;
          }
        }

        .Select-value {
          line-height: 47px;
        }
      }
    }

    .Select-menu-outer {
      .Select-menu {
        .Select-option {
          color: #4a4a4a;
          // font-size: 1.4em;
          line-height: normal;
          padding: 10px 10px;

          font-weight: 400;

          &.is-focused {
            background-color: $border-color;
          }

          &.is-selected {
            background-color: #e8e8e8;
          }
        }
      }
    }
  }
}

.form-control {
  &.form-control--success {
    .Select-control {
      border-color: $success-color;
    }
  }

  &.form-control--warning {
    .Select-control {
      border-color: $warning-color;
    }
  }

  &.form-control--error {
    .Select-control {
      border-color: $error-color;
    }
  }
}

//================================================== RADIO

/* The container */
.container-radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 22px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    visibility: hidden;

    /* When the radio button is checked, add a blue background */
    &:checked ~ .checkmark {
      background-color: $lumio-color;
      border-color: $lumio-color;
    }
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid #dddddd;

    /* Create the indicator (the dot/circle - hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;

      /* Style the indicator (dot/circle) */
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }
}

/* On mouse-over, add a grey background color */
.container-radio {
  &:hover {
    input ~ .checkmark {
      background-color: #dddddd;
    }

    input:checked ~ .checkmark {
      background-color: $lumio-color;
    }
  }
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
  display: block;
}

//================================================== DAY PICKER INPUT
.DayPickerInput {
  display: block !important;
}

.DayPickerInput-Overlay {
  z-index: 1000;
}

.DayPicker-wrapper {
  color: #4a4a4a;

  .DayPicker-Footer {
    text-align: center;
  }
}

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

//================================================== INPUT TIME PICKER
input.rc-time-picker-input {
  padding: 12px;
  line-height: normal;
  height: auto;
  font-weight: 400;
  border: none;
  outline: none;
  border-radius: 8px;
}

.rc-time-picker-panel-select {
  > ul {
    > li {
      padding: 8px 0 8px 16px;
      font-size: 14px;
    }
  }
}

.rc-time-picker-panel-inner {
  .rc-time-picker-panel-input-wrap {
    padding: 15px;
    font-size: 14px;

    .rc-time-picker-panel-clear-btn {
      top: 15px;
    }
  }
}
