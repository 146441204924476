    /*
    Flaticon icon font: Flaticon
    Creation date: 26/10/2017 09:50
    */

    $font-ic-vms: "\e900";
    $font-ic-template: "\e901";
    $font-ic-webview: "\e902";
    $font-ic-media: "\e903";
    $font-ic-stream: "\e904";
    $font-ic-audio: "\e905";
    $font-ic-feed : "\e906";


    $font-ic-settings: "\f103";
    $font-ic-arrow-up: "\f104";
    $font-ic-caret-up: "\f105";
    $font-ic-add: "\f106";
    $font-ic-delete: "\f108";
    $font-ic-image: "\f109";
    $font-ic-link: "\f10a";
    $font-ic-clock: "\f10b";
    $font-ic-handle: "\f10c";
    $font-ic-calendar: "\f10d";
    $font-ic-warning: "\f10e";
    $font-ic-more: "\f10f";
    $font-ic-speker: "\f111";
    $font-ic-radio-btn: "\f112";
    $font-ic-checkbox: "\f113";
    $font-ic-search: "\f114";
    $font-ic-folder: "\f115";
    $font-ic-folder-open: "\f116";
    $font-ic-folder-plus: "\f117";
    $font-ic-folder-minus: "\f118";
    $font-ic-folder-download: "\f119";
    $font-ic-folder-upload: "\f11a";
    $font-ic-home: "\f11b";

    $font-ic-caret-down: "\f11c";
    $font-ic-caret-left: "\f11d";
    $font-ic-caret-right: "\f11e";
    
    $font-ic-arrow-right: "\f120";
    $font-ic-arrow-left: "\f121";
    $font-ic-arrow-down: "\f122";
    $font-ic-video: "\f123";

    $font-ic-previous: "\f124";
    $font-ic-next: "\f125";
    $font-ic-pause: "\f126";

    $font-ic-audiotrack: "\f127";
    $font-ic-handle-square: "\f129";

    $font-ic-play-o: "\f12a";
    $font-ic-pause-o: "\f12b";
    $font-ic-stop-o: "\f12c";
    $font-ic-previous-o: "\f12d";
    $font-ic-next-o: "\f12e";
    $font-ic-backward-o: "\f12f";
    $font-ic-forward-o: "\f130";

    $font-ic-alignment-right: "\f131";
    $font-ic-alignment-center: "\f132";
    $font-ic-alignment-left: "\f133";

    $font-ic-checked : "\f134";
    $font-ic-lumio-logo : "\f135";

    $font-ic-rss-square: "\f137";
    $font-ic-rss: "\f138";
    $font-ic-instagram: "\f139";
    $font-ic-twitter: "\f13a";
    $font-ic-facebook-square: "\f13b";
    $font-ic-facebook: "\f13c";

    $font-ic-download: "\f13d";
    $font-ic-refresh: "\f13e";
    $font-ic-tags: "\e90e";

    @font-face {
      font-family: "Lumio Icon";
      src: url("./icon.eot");
      src: url("./icon.eot?#iefix") format("embedded-opentype"), url("./icon.woff") format("woff"), url("./icon.ttf") format("truetype"), url("./icon.svg#lumio") format("svg");
      font-weight: normal;
      font-style: normal;
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
      @font-face {
        font-family: "Lumio Icon";
        src: url("./icon.svg#Flaticon") format("svg");
      }
    }

    .ic:before {
      display: inline-block;
      font-family: "Lumio Icon";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
    }

    .ic-vms:before,.ic-vms2:before {
      content: $font-ic-vms;
    }

    .ic-template:before {
      content: $font-ic-template;
    }

    .ic-webview:before {
      content: $font-ic-webview;
    }

    .ic-settings:before {
      content: $font-ic-settings;
    }

    .ic-media:before {
      content: $font-ic-media;
    }

    .ic-caret-up:before {
      content: $font-ic-caret-up;
    }

    .ic-caret-down:before {
      content: $font-ic-caret-down;
    }

    .ic-caret-right:before{
      content: $font-ic-caret-right;
    }

    .ic-caret-left:before {
      content: $font-ic-caret-left;
    }

    .ic-arrow-up:before {
      content: $font-ic-arrow-up;
    }

    .ic-arrow-down:before {
      content: $font-ic-arrow-down;
    }

    .ic-arrow-right:before  {
      content: $font-ic-arrow-right;
    }

    .ic-arrow-left:before {
      content: $font-ic-arrow-left;
    }

    .ic-add:before {
      content: $font-ic-add;
    }

    .ic-close:before {
      transform: rotate(45Deg);
      content: $font-ic-add;
    }

    .ic-delete:before{
      content: $font-ic-delete;
    }

    .ic-image:before{
      content: $font-ic-image;
    }

    .ic-link:before{
      content: $font-ic-link;
    }

    .ic-clock:before{
      content: $font-ic-clock;
    }

    .ic-handle:before{
      content: $font-ic-handle;
    }

    .ic-calendar:before{
      content: $font-ic-calendar;
    }

    .ic-warning:before{
      content: $font-ic-warning;
    }

    .ic-more:before{
      content: $font-ic-more;
    }

    .ic-more-horizontal:before{
      content: $font-ic-more;
      transform: rotate(90Deg);
    }


    .ic-stream:before{
      content: $font-ic-stream;
    }

    .ic-audio:before{
      content: $font-ic-audio;
    }

    .ic-radio-btn:before{
      content: $font-ic-radio-btn;
    }

    .ic-checkbox:before{
      content: $font-ic-checkbox;
    }

    .ic-search:before{
      content: $font-ic-search;
    }

    .ic-folder:before{
      content: $font-ic-folder;
    }

    .ic-folder-open:before{
      content: $font-ic-folder-open;
    }

    .ic-folder-plus:before{
      content: $font-ic-folder-plus;
    }

    .ic-folder-minus:before{
      content: $font-ic-folder-minus;
    }

    .ic-folder-download:before{
      content: $font-ic-folder-download;
    }

    .ic-folder-upload:before{
      content: $font-ic-folder-upload;
    }

    .ic-home:before{
        content: $font-ic-home;
    }

    .ic-video:before, .ic-play:before{
        content: $font-ic-video;
    }

  .ic-previous:before{
      content: $font-ic-previous;
  }
  
  .ic-next:before{
    content: $font-ic-next;
  }

  .ic-pause:before{
    content: $font-ic-pause;
  }

  .ic-sound:before{
    content: $font-ic-speker;
  }

  .ic-audiotrack:before{
    content: $font-ic-audiotrack;
  }

  .ic-handle-square:before{
    content: $font-ic-handle-square;
  }


  .ic-play-o:before{
    content: $font-ic-play-o;
  }

  .ic-stop-o:before{
    content: $font-ic-stop-o;
  }

  .ic-pause-o:before{
    content: $font-ic-pause-o;
  }

  .ic-next-o:before{
    content: $font-ic-next-o;
  }

  .ic-previous-o:before{
    content: $font-ic-previous-o;
  }

  .ic-backward-o:before{
    content: $font-ic-backward-o;
  }

  .ic-forward-o:before{
    content: $font-ic-forward-o;
  }

  .ic-alignment-center:before{
    content: $font-ic-alignment-center;
  }

  .ic-alignment-left:before{
    content: $font-ic-alignment-left;
  }

  .ic-alignment-right:before{
    content: $font-ic-alignment-right;
  }

  .ic-checked:before{
    content: $font-ic-checked;
  }

  .ic-lumio-logo:before{
    content: $font-ic-lumio-logo;
  }

  .ic-feed:before{
    content: $font-ic-feed;
  }

  /* social icon */
  
  .ic-rss:before{
    content: $font-ic-rss;
  }

  .ic-rss-square:before{
    content: $font-ic-rss-square;
  }

  .ic-facebook:before{
    content: $font-ic-facebook;
  }

  .ic-facebook-square:before{
    content: $font-ic-facebook-square;
  }


  .ic-instagram:before{
    content: $font-ic-instagram;
  }

  .ic-twitter:before{
    content: $font-ic-twitter;
  }

  .ic-download:before{
    content: $font-ic-download;
  }

  .ic-refresh:before{
    content: $font-ic-refresh;
  }

  .ic-tags:before{
    content: $font-ic-tags;
  }